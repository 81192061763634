<template>
	<div>
		<searchCard>
			<h6>요청 일자</h6>
			<div class="flex">
				<div class="flex top-1.5">
					<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value=""
						>전체기간
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
				</div>
				<datePicker class="w-1/7" model="opt.startDate" />
				<span class="p-2 lh-6">~</span>
				<datePicker class="w-1/7" model="opt.endDate" />
			</div>
			<div class="flex">
				<div class="w-1/8">
					<h6>요청회원 구분</h6>
					<v-select v-model="opt.userType" :clearable="false" :options="userTypeOpts" />
				</div>
				<div class="w-1/8 ml-8">
					<h6>요청 경로</h6>
					<v-select v-model="opt.requestProductPath" :clearable="false" :options="pathOpts" />
				</div>
				<div class="w-1/5 ml-8">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6 class="block">&nbsp;</h6>
					<div>
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
					</div>
				</div>
				<div>
					<h6 class="block">&nbsp;</h6>
					<b-button class="px-10 top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="mb-4 float-right z-10" @click="excelExport">엑셀 다운로드 요청</b-button>
			<tb :inf="inf" :res="res" />
		</b-card>
	</div>
</template>

<script>
const now = new Date(),
	userTypeOpts = [
		{ label: '전체', value: 'All' },
		{ label: '일반회원', value: 'Normal' },
		{ label: '비회원', value: 'Anonymous' },
		{ label: '핫티스트', value: 'Hottist' },
	],
	pathOpts = [
		{ label: '전체', value: '' },
		{ label: '핫트샵', value: 'HOTT_SHOP' },
		{
			label: '핫트링크',
			value: 'HOTT_LINK',
		},
		{
			label: '핫트메인',
			value: 'HOTT_MAIN',
		},
		{ label: '핫딜센터', value: 'HOTTDEAL_CENTER' },
	],
	searchKeyOpts = [
		{ label: '전체', value: 'ALL' },
		{ label: '요청회원 아이디', value: 'REQ_HOTT_ID' },
		{ label: '요청상품', value: 'PRODUCT' },
		{ label: '판매사이트 url', value: 'PRODUCT_URL' },
		{ label: '접수받은 핫티스트 핫트 아이디', value: 'HOTTIST_HOTT_ID' },
		{ label: '접수받은 핫티스트 인스타 아이디', value: 'HOTTIST_INSTA_ID' },
	],
	defOpt = {
		period: '',
		startDate: getMonth('', -1),
		endDate: now,

		userType: userTypeOpts[0],
		requestProductPath: pathOpts[0],

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	data() {
		return {
			userTypeOpts,
			pathOpts,
			searchKeyOpts,
			defOpt,
			stepOpts: [],
			typeOpts: [],

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'SEQ', model: 'idx', size: 2 },

				{ title: '요청 일자', key: 'requestDateTime', size: 4 },

				{ title: '요청 회원 구분', key: 'requestUserType', size: 3 },
				{ title: '요청 회원 아이디', key: 'requestHottId', size: 3 },

				{ title: '요청상품', key: 'productName', size: 6, notCenter: 1 },
				{ title: '판매사이트 URL', key: 'productUrl', size: 6, notCenter: 1, model: 'url' },

				{ title: '요청경로', key: 'requestProductPath', size: 3 },
				{ title: '접수받은 핫티스트 핫트아이디', key: 'hottistHottId', size: 6 },
				{
					title: '접수받은 핫티스트 인스타아이디',
					key: 'hottistInstaId',
					size: 6,
					model: 'insta',
				},
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				fromDate = opt.startDate.get_time('d'),
				toDate = opt.endDate.get_time('d'),
				requestUserType = getDropdownValue(opt.userType),
				requestProductPath = getDropdownValue(opt.requestProductPath),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchKeyword = opt.searchKeyword

			let data = {
					backOfficeDto: {
						//이건 또 뭔..
						requestUserType,
					},
					pagingDto: getPaging(),
					//이것도 기존과 키가 다르다..
				},
				error = []
			if (requestProductPath) data.backOfficeDto.requestProductPath = requestProductPath

			if (opt.period) {
				if (fromDate > toDate) error.push('시작일이 종료일보다 큽니다.')
				else data.backOfficeDto = { ...data.backOfficeDto, fromDate, toDate }
			} else data.backOfficeDto = { ...data.backOfficeDto, fromDate: '2000-01-01', toDate: '2099-12-31' }

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (searchKeyword) data.backOfficeDto = { ...data.backOfficeDto, searchKey, searchKeyword }
			if (error.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.selectedItem = {}
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.pagingDto.pageNo = page - 1
			let searchData = { ...this.lastOpt },
				url = '/hottShopSelect/selectHottshopRequestProductList'
			if (ex) {
				url = '/hottShopSelect/downloadHottshopRequestProductList'
				searchData.backOfficeDto = { ...searchData.backOfficeDto, excelInfo: ex.get() }
			}

			return postApi(url, searchData).then(res => {
				if (!ex) {
					res.list.map(v => {
						if (v.productUrl && v.productUrl.indexOf('http') != 0) v.productUrl = 'http://' + v.productUrl
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt.backOfficeDto,
				ex = new excel()

			if (opt.toDate != '2099-12-31') ex.date(opt.fromDate, opt.toDate, '요청 일자')
			else ex.key('요청 일자', '전체 기간')

			ex.dropdown(opt.requestUserType, this.userTypeOpts, '요청회원 구분')
			ex.dropdown(opt.requestProductPath, this.pathOpts, '요청경로')

			if (opt.searchKey) {
				ex.dropdown(opt.searchKey, this.searchKeyOpts, '검색 키워드')
				ex.key('검색어', opt.searchKeyword)
			}
			reload(this, false, ex)
		},
	},
	created() {
		this.search()
	},
}
</script>
